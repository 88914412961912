<template>
    <div class="korisnici">
        <section class="app-user-list">
            <div class="card">
                <div class="card-datatable table-responsive pt-0">
                    <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                        <div class="d-flex justify-content-between align-items-center header-actions mx-1 row mt-75">
                            <div class="col-lg-12 col-xl-6">

                            </div>
                            <div class="col-lg-12 col-xl-6 pl-xl-75 pl-0">
                                <div class="dt-action-buttons text-xl-right text-lg-left text-md-right text-left d-flex align-items-center justify-content-lg-end align-items-center flex-sm-nowrap flex-wrap mr-1">
                                    <div class="mr-1">
                                        <div id="DataTables_Table_0_filter" class="dataTables_filter"><label><input type="search" v-model="searchstring" placeholder="Pretraga" v-on:change="Pretraga()" class="form-control"></label>
                                        </div>
                                    </div>
                                    <div class="dt-buttons btn-group flex-wrap">
                                        <button class="btn add-new btn-primary mt-50" tabindex="0" aria-controls="DataTables_Table_0" type="button" data-toggle="modal" data-target="#modals-slide-in">
                                            <span>Dodaj Novog</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table class="user-list-table table dataTable no-footer dtr-column" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info">
                            <thead class="thead-light">
                            <tr role="row">
                                <th class="control sorting_disabled" rowspan="1" colspan="1" aria-label="">ID</th>
                                <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1"  aria-label="User: activate to sort column ascending">Ime i Prezime</th>
                                <th class="sorting_desc" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-sort="descending" aria-label="Email: activate to sort column ascending">Email</th>
                                <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Role: activate to sort column ascending">Aktivan</th>
                                <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Plan: activate to sort column ascending">Telefon</th>
                                <th class="sorting_disabled" rowspan="1" colspan="1"  aria-label="Actions">Actions</th>
                            </tr>
                            </thead>
                            <tbody v-if="!loading">
                            <tr class="odd" v-for="user in users" :key="user.id"  >
                                <td valign="top" colspan="1">{{user.id}}</td>
                                <td valign="top" colspan="1" v-on:click="viewKorisnik(user)">{{user.ime+" "+user.prezime}}</td>
                                <td valign="top" colspan="1">{{user.email}}</td>
                                <td valign="top" colspan="1">
                                    <button type="button" v-if="user.active" class="btn btn-danger waves-effect waves-float waves-light" v-on:click="Deaktiviraj(user.id)">Deaktiviraj</button>
                                    <button type="button" v-if="!user.active" class="btn btn-success waves-effect waves-float waves-light" v-on:click="Aktiviraj(user.id)">Aktiviraj</button>
                                </td>
                                <td valign="top" colspan="1">{{user.tel}}</td>
                                <td valign="top" colspan="1">{{user.rank}}</td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                </div>
                <!-- Modal to add new user starts-->
                <div class="modal modal-slide-in new-user-modal fade" id="modals-slide-in">
                    <div class="modal-dialog">
                        <form class="add-new-user modal-content pt-0" novalidate="novalidate">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
                            <div class="modal-header mb-1">
                                <h5 class="modal-title" id="exampleModalLabel">New User</h5>
                            </div>
                            <div class="modal-body flex-grow-1">
                                <div class="form-group">
                                    <label class="form-label" for="basic-icon-default-fullname" >Ime</label>
                                    <input type="text" class="form-control dt-full-name" id="basic-icon-default-fullname" v-model="ime" placeholder="John" name="user-fullname" aria-label="John" aria-describedby="basic-icon-default-fullname2">
                                </div>
                                <div class="form-group">
                                    <label class="form-label" for="basic-icon-default-fullname">Prezime</label>
                                    <input type="text" class="form-control dt-full-name" id="basic-icon-default-surname" v-model="prezime" placeholder="Doe" name="user-fullname" aria-label="Doe" aria-describedby="basic-icon-default-fullname2">
                                </div>
                                <div class="form-group">
                                    <label class="form-label" for="basic-icon-default-uname">Email</label>
                                    <input type="text" id="basic-icon-default-uname" class="form-control dt-uname" v-model="email" placeholder="john@doe.com" aria-label="jdoe1" aria-describedby="basic-icon-default-uname2" name="user-name">
                                </div>

                                <div class="form-group">
                                    <label class="form-label" for="basic-icon-default-uname">Telefon</label>
                                    <input type="text" id="basic-icon-default-tel" class="form-control dt-uname" v-model="telefon" placeholder="john@doe.com" aria-label="jdoe1" aria-describedby="basic-icon-default-uname2" name="user-name">
                                </div>
                                <div class="form-group">
                                    <label class="form-label" for="user-role">Role</label>
                                    <select id="user-role" class="form-control" v-model="usertype">
                                        <option value="korisnik">Korisnik</option>
                                        <option value="instruktor">Instruktor</option>
                                    </select>
                                </div>
                                <button type="button" class="btn btn-primary mr-1 data-submit waves-effect waves-float waves-light" v-on:click="Dodaj()">Submit</button>
                                <button type="reset" class="btn btn-outline-secondary waves-effect" data-dismiss="modal">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- Modal to add new user Ends-->
            </div>
            <!-- list section end -->
        </section>
    </div>
</template>
<script>
    import api from '../api'
  export default {
    name: 'korisnici',
    components: {
    },
      mounted(){
        this.getUsers().then(data => {
              console.log(data.data.Users);
              this.users=data.data.Users;
              this.loading=false;
          });
      },
      created(){

      },
      data() {
          return {
              ime:"",
              prezime:"",
              email:"",
              telefon:"",
              usertype:"",
              users:null,
              loading:true,
              searchstring:'',
          }
      },
    methods:{
        async getUsers() {
            return api.getKorisnici();
        },
        async addUser(user)
        {
            return api.dodajKorisnika(user);
        },
        async asyDeaktiviraj(id)
        {
            return api.deactivate(id);
        },
        async asyAktiviraj(id)
        {
            return api.activate(id);
        },
        Dodaj:function(){
            let user={
                ime:this.ime,
                prezime:this.prezime,
                email:this.email,
                telefon:this.telefon,
                usertype:this.usertype,
            }

            this.loading=true;
            this.addUser(user).then(data => {
                this.getUsers().then(data => {
                    console.log(data.data.Users);
                   // this.users=data.data.Users;
                    this.loading=false;

                });
            });
            let self=this;
            setTimeout(() => self.$router.go(0), 500);
            this.ime='';
            this.prezime='';
            this.email='';
            this.telefon='';
            this.usertype='';
            return '';
        },
        viewKorisnik: function (user) {
            this.$router.push({ name: 'view-korisnik', params: { id: user.id,user:user } })
        },
        Deaktiviraj:function(id){
            this.asyDeaktiviraj(id);
            let self=this;
            setTimeout(() => self.$router.go(0), 500);

        },
        Aktiviraj:function(id){
            this.asyAktiviraj(id);
            let self=this;
            setTimeout(() => self.$router.go(0), 500);

        },
        Pretraga(){
            if(this.searchstring==='')
            {
                this.getUsers().then(data => {
                    console.log(data.data.Users);
                    this.users=data.data.Users;
                    this.loading=false;
                });
            }else
            {
                this.users=   this.users.filter( user => {
                return user.ime.toLowerCase().includes(this.searchstring.toLowerCase())||user.prezime.toLowerCase().includes(this.searchstring.toLowerCase())||user.email.toLowerCase().includes(this.searchstring.toLowerCase())
            })
            }
        }
    }
  }
</script>
<style lang="scss">
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }
    .table thead {
        background: red  !important;
        color: white;
    }
    thead.thead-light tr {
        background: red  !important;
        color: white;
    }
    .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th, .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
        background-color: red ;
        color: white;
    }
</style>
